@import 'variables.scss';
@import 'mixins.scss';

$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) and (orientation: portrait) {

    // header
    .header {
        img {
            width: 30%;
        }

        .menu-bar {
            display: none;

        }

        .menu-icon {
            display: block;
        }
    }

    //banner
    .banner {
        margin-top: 15%;
    }

    // slider
    .slider {
        margin-top: 15%;
    }

    //about
    .about-section {
        .about-body {
            flex-wrap: wrap;

            .about-left {
                display: none;
            }

            .about-right {
                padding: 0;
                width: 100%;

                span {
                    font-size: 3vw;
                    margin-bottom: 3px;
                }

                h2 {
                    font-size: 3.5vw;
                    padding-bottom: 10px;
                }

                .text {
                    font-size: 2.3vw;
                    margin-bottom: 10px;
                }
            }
        }
    }

    .about-page {
        .about-story {
            flex-direction: column;

            .story-content {
                width: 100%;

                h2 {
                    font-size: 3.5vw;
                }

                p {
                    font-size: 3vw;
                }
            }

            .story-img {
                width: 100%;
                margin-bottom: 7%;

                img {
                    padding: 0;
                }
            }
        }

        .about-values {
            .values-content {
                @include flex;
                flex-direction: column;
                margin-top: 15%;

                .values-card {
                    margin: 5% 0;

                    p {
                        font-size: 8px;
                        width: 50%;
                        height: 11vh;
                        padding: 2%;
                    }

                    &:hover {
                        transition: .5s all ease;
                        transform: none !important;

                        &:nth-child(odd) {
                            .values-svg {
                                transform: translate(50%, -50%);
                            }
                        }

                        .values-svg {
                            transform: translate(-50%, -50%);
                        }

                    }

                    .values-svg {

                        width: 80px;
                        height: 80px;

                        svg {

                            width: 80px;
                            height: 80px;


                        }

                        h3 {
                            font-size: 16px;
                            height: 40%;

                        }
                    }


                    &:nth-child(1) {
                        .values-svg {
                            left: 20%;

                            svg {

                                path {
                                    fill: #8B3A3A;
                                }
                            }

                            h3 {
                                top: 120%;
                            }
                        }

                        p {
                            top: -40%;
                            left: 50%;
                        }
                    }

                    &:nth-child(2) {
                        .values-svg {
                            left: 80%;

                            svg {
                                padding: 10%;

                                path {
                                    fill: #1D88EA;
                                }
                            }

                            h3 {
                                top: 120%;
                            }

                        }

                        p {
                            top: -30%;
                            left: 0;
                        }
                    }


                    &:nth-child(3) {
                        .values-svg {
                            left: 20%;

                            svg {
                                path {
                                    fill: #FF4D4D;
                                }
                            }

                            h3 {
                                top: 120%;
                            }
                        }

                        p {
                            top: -30%;
                            left: 50%;
                        }
                    }


                    &:nth-child(4) {
                        .values-svg {
                            left: 80%;

                            svg {
                                path {
                                    fill: #ECAC57;
                                }
                            }

                            h3 {
                                top: 120%;
                            }
                        }

                        p {
                            top: -30%;
                            left: 0;
                        }
                    }

                    &:nth-child(5) {
                        .values-svg {
                            left: 20%;

                            svg {
                                path {
                                    fill: #6E8B3D;
                                }
                            }

                            h3 {
                                top: 120%;
                            }
                        }

                        p {
                            top: -20%;
                            left: 50%;
                        }
                    }

                }
            }

        }

        .team {

            .team-member {
                h4 {
                    font-size: 3vw;
                }

                p {
                    font-size: 2.5vw;
                }
            }

            .col-md-3 {
                width: calc(100% / 2);
            }
        }


    }


    //services

    .services {
        .services-body {
            flex-wrap: wrap;
            margin: 0;

            .services-card {
                width: 100%;
                margin: 5%;
                display: block;

                &:nth-child(2) {
                    margin: 0 5%;
                }

                h3 {
                    font-size: 4.5vw;
                    width: 90%;
                }

                &:hover {
                    width: 100%;

                }
            }
        }

        // .service-headline {
        //     span {
        //         font-size: 3vw;
        //         margin-bottom: 0;
        //     }

        //     h2 {
        //         font-size: 2.5vw;
        //     }
        // }
    }

    .service-page {
        #service-section {
           #case-study{
            .case-study-card{
                p{
                    font-size: 4vw;
                }
                &:hover{
                    li{
                        font-size: 2.3vw;
                    }
                }
               
            }
           }
        }

        #service-service {
            .service-service {
                flex-wrap: wrap;

                .service-service-body {
                    width: 100%;
                    margin-bottom: 3%;

                    .service-service-text {
                        h4 {
                            font-size: 3.5vw;
                        }

                        ul {
                            li {
                                font-size: 3.3vw;
                            }
                        }
                    }

                    button {
                        width: 80px;
                        height: 80px;
                        font-size: 2.5vw;
                    }
                }
            }
        }

        #why-us {
            .features-area {
                .equal-height {
                    &::after {
                        display: none;
                    }
                }

                .col-md-4 {
                    width: 100%;
                }

                // .row {
                //     flex-wrap: wrap !important;
                // }

                .item {
                    padding: 5% 0;

                    .info {
                        h4 {
                            font-size: 3.5vw;
                        }

                        ul {
                            li {
                                font-size: 2.8vw;
                            }
                        }
                    }

                }
            }
        }

        #case-study {
            margin-bottom: 10%;

            .case-study {
                flex-wrap: wrap;

                .case-study-card {
                    width: 100%;

                    .case-study-card-caption {
                        h4 {
                            font-size: 4vw;
                        }

                        p {
                            font-size: 5vw;
                        }

                        .case-study-hidden {
                            font-size: 2.5vw;
                        }
                    }
                }
            }
        }
    }

    //map
    .map {
        .map-content {
            flex-wrap: wrap;

            button {
                width: 80%;
                font-size: 2.5vw;
                padding: 1%;
                margin: 2% 0;
            }
        }

        .map-img {
            flex-wrap: wrap;
            padding: 0;

            .panelist {
                width: 100%;

                h3 {
                    font-size: 3vw;
                    text-align: center;
                    margin-bottom: 5%;
                }

                ul {
                    margin: 5% 0;
                    margin-left: 0;
                    @include flex;
                    flex-wrap: wrap;
                    margin: 0 5%;

                    li {
                        font-size: 2.8vw;
                        width: 33%;

                        .post-it {
                            border-radius: 0 0 0 10px/15px;
                            height: 60px;
                            left: -90%;
                            padding: 1px;
                            width: 60px;

                            p {
                                font-size: 10px;
                                margin-bottom: -8px;
                            }

                            h5 {
                                font-size: 9px;
                            }
                        }

                        .post1 {
                            top: 630%;
                            left: 10%;
                        }

                        .post2 {
                            top: 1200%;
                            left: -35%;
                        }

                        .post3 {
                            top: 740%;
                            left: -70%;
                        }

                        .post4 {
                            top: 730%;
                            left: 155%;
                        }

                        .post5 {
                            top: 730%;
                            left: 90%;
                        }

                        .post6 {
                            top: 1130%;
                            left: 50%;
                        }
                    }
                }
            }

            .map-right {
                width: 100%;
                margin: 5% 0;

                .map-pin {
                    svg {
                        height: 10px;
                        width: 10px;
                    }


                }

                .pin1 {
                    left: 15%;
                    top: 15%;
                }

                .pin2 {
                    left: 50%;
                    top: 25%;
                }

                .pin3 {
                    bottom: 30%;
                    left: 30%;
                }

                .pin4 {
                    left: 57%;
                    top: 40%;
                }

                .pin5 {
                    right: 30%;
                    top: 40%;
                }

                .pin6 {
                    bottom: 20%;
                    right: 12%;
                }
            }
        }
    }

    //achievements
    .achievements {
        .a-card p {
            font-size: 2vw;
        }
    }

    // why-us
    #whyus {
        .why-card {

            &:first-child {
                padding: 0;
                margin-top: 5%;

                .why-card-img {
                    img {
                        width: calc(100% / 4.3);
                        padding: 0 5%;
                    }
                }

                .why-card-heading {
                    h3 {
                        width: calc(100% / 4.3);
                        font-size: 1.5vw;
                    }
                }
            }

            &:last-child {


                .why-card-body {
                    padding: 3% 5%;

                    p {
                        font-size: 2.5vw;
                    }
                }
            }
        }
    }

    // news
    .news {
        .news-body {
            flex-wrap: wrap;

            .news-body-content {
                width: 100%;

                .news-content {
                    h3 {
                        font-size: 4vw;
                    }


                }

                .news-content-overlay {
                    p {
                        font-size: 3.9vw;
                    }

                    a {
                        font-size: 3vw;
                    }
                }
            }
        }
    }

    //contact
    .contact-page {
        .contact-body {
            flex-wrap: wrap;


            .contact-left {
                width: 100%;
                flex-direction: row;
                flex-wrap: wrap;

                .contact-left-body {
                    flex-direction: column;
                    width: 48%;
                    margin: 1%;

                    svg {
                        width: 30px;
                        height: 30px;
                        display: block;
                        margin: 5% auto;
                    }
                }

                .contact-left-caption {
                    margin-left: 0;
                    margin: auto;

                    a {
                        font-size: 2vw;
                    }
                }

                .location {
                    width: 100%;

                    .svg {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .contact-left-caption {
                        flex-direction: row;

                        a {
                            margin: 0 2%;
                            text-align: center;
                            width: calc(100% /3);
                        }
                    }
                }
            }

            .contact-right {
                width: 100%;

                form {

                    input,
                    textarea {
                        font-size: 2.4vw;
                    }

                    button {
                        font-size: 2.5vw;
                        padding: 3%;
                        border-radius: 5px;
                    }
                }
            }

        }

        .contact-map {
            margin-bottom: 15%;
        }
    }

    // footer
    #footer {
        background-color: rgba(87, 189, 175, .1);
        background-image: none;
        margin-top: -5%;
        padding-top: 5%;

        .footer-bg {
            display: none;
        }

        footer {
            flex-wrap: wrap;

            .footer-content {
                width: 100% !important;
            }

            .footer-content {
                h6 {
                    font-size: 2.5vw;
                }
            }

            .left {
                .footer-left-body {
                    h4 {
                        font-size: 3vw;
                    }

                    p {
                        font-size: 2.5vw;
                    }
                }

                img {
                    width: 50%;
                    margin-bottom: 0;
                }
            }

            .right {
                h3 {
                    font-size: 3.5vw;
                }

                .footer-follow {
                    margin-bottom: 5%;
                }
            }
        }

        .copyright {
            font-size: 2vw;
        }
    }

    // privacy policy
    .privacy {
        margin-top: 23%;

        h3 {
            font-size: 2.3vw;
        }

        p,
        li {
            font-size: 1.8vw;
        }
    }


}

@media (min-width: 481px) and (max-width: $md) and (orientation: landscape) {

    // header
    .header {
        img {
            width: 30%;
        }

        .menu-bar {
            display: none;

        }

        .menu-icon {
            display: block;
        }
    }

    //banner
    .banner {
        margin-top: 15%;
    }

    // slider
    .slider {
        margin-top: 15%;
    }

    // about
    .about-section {
        .about-body {
            flex-wrap: wrap;

            .about-left {
                display: none;
            }

            .about-right {
                padding: 0;
                width: 100%;

                span {
                    font-size: 3vw;
                    margin-bottom: 3px;
                }

                h2 {
                    font-size: 3.5vw;
                    padding-bottom: 10px;
                }

                .text {
                    font-size: 2.3vw;
                    margin-bottom: 10px;
                }
            }
        }
    }

    .about-page {
        .about-story {
            flex-direction: column;

            .story-content {
                width: 100%;

                h2 {
                    font-size: 3.5vw;
                }

                p {
                    font-size: 3vw;
                }
            }

            .story-img {
                width: 100%;
                margin-bottom: 7%;

                img {
                    padding: 0;
                }
            }
        }

        .about-values {
            .values-content {
                @include flex;
                flex-direction: column;
                margin-top: 15%;

                .values-card {
                    margin: 5% 0;

                    p {
                        font-size: 12px;
                        width: 60%;
                        height: 13vh;
                        padding: 2%;
                    }

                    &:hover {

                        &:nth-child(odd) {
                            .values-svg {
                                transform: translate(60%, -50%);
                            }
                        }

                        .values-svg {
                            transform: translate(-50%, -50%);
                        }

                    }

                    .values-svg {

                        width: 100px;
                        height: 100px;

                        svg {
                            width: 100px;
                            height: 100px;
                        }

                        h3 {
                            font-size: 16px;
                            height: 40%;

                        }
                    }


                    &:nth-child(1) {
                        .values-svg {
                            left: 20%;

                            svg {

                                path {
                                    fill: #8B3A3A;
                                }
                            }

                            h3 {
                                top: 120%;
                            }
                        }

                        p {
                            top: -40%;
                            left: 40%;
                        }
                    }

                    &:nth-child(2) {
                        .values-svg {
                            left: 80%;

                            svg {
                                padding: 10%;

                                path {
                                    fill: #1D88EA;
                                }
                            }

                            h3 {
                                top: 120%;
                            }

                        }

                        p {
                            top: -30%;
                            left: 0;
                        }
                    }


                    &:nth-child(3) {
                        .values-svg {
                            left: 20%;

                            svg {
                                path {
                                    fill: #FF4D4D;
                                }
                            }

                            h3 {
                                top: 120%;
                            }
                        }

                        p {
                            top: -30%;
                            left: 40%;
                        }
                    }


                    &:nth-child(4) {
                        .values-svg {
                            left: 80%;

                            svg {
                                path {
                                    fill: #ECAC57;
                                }
                            }

                            h3 {
                                top: 120%;
                            }
                        }

                        p {
                            top: -30%;
                            left: 0;
                        }
                    }

                    &:nth-child(5) {
                        .values-svg {
                            left: 20%;

                            svg {
                                path {
                                    fill: #6E8B3D;
                                }
                            }

                            h3 {
                                top: 120%;
                            }
                        }

                        p {
                            top: -20%;
                            left: 40%;
                        }
                    }

                }
            }

        }

        .team {

            .team-member {
                h4 {
                    font-size: 3vw;
                }

                p {
                    font-size: 2.5vw;
                }
            }

            .col-md-3 {
                width: calc(100% / 2);
            }
        }


    }

    //services

    .services {
        .services-body {
            flex-wrap: wrap;
            margin: 0;

            .services-card {
                width: 100%;
                margin: 5%;
                display: block;

                &:nth-child(2) {
                    margin: 0 5%;
                }

                h3 {
                    font-size: 4.5vw;
                    width: 90%;
                }

                &:hover {
                    width: 100%;

                }
            }
        }
    }


    .service-page {
        #service-section {
            #case-study{
             .case-study-card{
                 p{
                     font-size: 4vw;
                 }
                 &:hover{
                     li{
                         font-size: 2.5vw;
                     }
                 }
                
             }
            }
         }

        #service-service {
            .service-service {
                flex-wrap: wrap;

                .service-service-body {
                    width: 100%;
                    margin-bottom: 3%;

                    .service-service-text {
                        h4 {
                            font-size: 3.5vw;
                        }

                        ul {
                            li {
                                font-size: 3.3vw;
                            }
                        }
                    }

                    button {
                        width: 80px;
                        height: 80px;
                        font-size: 2.5vw;
                    }
                }
            }
        }

        #why-us {
            .features-area {
                .equal-height {
                    &::after {
                        display: none;
                    }
                }

                .col-md-4 {
                    width: 100%;
                }

                // .row {
                //     flex-wrap: wrap !important;
                // }

                .item {
                    padding: 5% 0;

                    .info {
                        h4 {
                            font-size: 3.5vw;
                        }

                        ul {
                            li {
                                font-size: 2.8vw;
                            }
                        }
                    }

                }
            }
        }


        #case-study {
            margin-bottom: 10%;

            .case-study {
                flex-wrap: wrap;

                .case-study-card {
                    width: 100%;

                    .case-study-card-caption {
                        h4 {
                            font-size: 4vw;
                        }

                        p {
                            font-size: 5vw;
                        }

                        .case-study-hidden {
                            font-size: 2.5vw;
                        }
                    }
                }
            }
        }
    }

    //map
    .map {
        .map-content {
            flex-wrap: wrap;

            button {
                width: 80%;
                font-size: 2.5vw;
                padding: 1%;
                margin: 2% 0;
            }
        }


        .map-img {
            flex-wrap: wrap;
            padding: 0;

            .panelist {
                width: 100%;

                h3 {
                    font-size: 3vw;
                    text-align: center;
                    margin-bottom: 5%;
                }

                ul {
                    margin: 5% 0;
                    margin-left: 0;
                    @include flex;
                    flex-wrap: wrap;
                    margin: 0 5%;

                    li {
                        font-size: 2.8vw;
                        width: 33%;

                        .post-it {
                            border-radius: 0 0 0 10px/15px;
                            height: 70px;
                            left: -90%;
                            padding: 3px;
                            width: 70px;

                            p {
                                font-size: 12px;
                                margin-bottom: -8px;
                            }

                            h5 {
                                font-size: 10px;
                            }
                        }

                        .post1 {
                            top: 630%;
                            left: 10%;
                        }

                        .post2 {
                            top: 1200%;
                            left: -35%;
                        }

                        .post3 {
                            top: 740%;
                            left: -70%;
                        }

                        .post4 {
                            top: 730%;
                            left: 155%;
                        }

                        .post5 {
                            top: 730%;
                            left: 90%;
                        }

                        .post6 {
                            top: 1130%;
                            left: 50%;
                        }
                    }
                }
            }

            .map-right {
                width: 100%;
                margin: 5% 0;

                .map-pin {
                    svg {
                        height: 10px;
                        width: 10px;
                    }

                    .post-it {
                        border-radius: 0 0 0 10px/15px;
                        height: 60px;
                        left: -200%;
                        padding: 1px;
                        width: 60px;
                    }
                }

                .pin1 {
                    left: 15%;
                    top: 15%;
                }

                .pin2 {
                    left: 50%;
                    top: 25%;
                }

                .pin3 {
                    bottom: 30%;
                    left: 30%;
                }

                .pin4 {
                    left: 57%;
                    top: 40%;
                }

                .pin5 {
                    right: 30%;
                    top: 40%;
                }

                .pin6 {
                    bottom: 20%;
                    right: 12%;
                }
            }
        }
    }

    //achievements
    .achievements {
        .a-card p {
            font-size: 2vw;
        }
    }

    // why-us
    #whyus {
        .why-card {

            &:first-child {
                padding: 0;
                margin-top: 5%;

                .why-card-img {
                    img {
                        width: calc(100% / 4.3);
                        padding: 0 5%;
                    }
                }

                .why-card-heading {
                    h3 {
                        width: calc(100% / 4.3);
                        font-size: 1.5vw;
                    }
                }
            }

            &:last-child {


                .why-card-body {
                    padding: 3% 5%;

                    p {
                        font-size: 2.5vw;
                    }
                }
            }
        }
    }


    // news
    .news {
        .news-body {
            flex-wrap: wrap;

            .news-body-content {
                width: 100%;

                .news-content {
                    h3 {
                        font-size: 4vw;
                    }


                }

                .news-content-overlay {
                    p {
                        font-size: 3.9vw;
                    }

                    a {
                        font-size: 3vw;
                    }
                }
            }
        }
    }

    //contact
    .contact-page {
        .contact-body {
            flex-wrap: wrap;


            .contact-left {
                width: 100%;
                flex-direction: row;
                flex-wrap: wrap;

                .contact-left-body {
                    flex-direction: column;
                    width: 48%;
                    margin: 1%;

                    svg {
                        width: 30px;
                        height: 30px;
                        display: block;
                        margin: 5% auto;
                    }
                }

                .contact-left-caption {
                    margin-left: 0;
                    margin: auto;

                    a {
                        font-size: 2vw;
                    }
                }

                .location {
                    width: 100%;

                    .svg {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .contact-left-caption {
                        flex-direction: row;

                        a {
                            margin: 0 2%;
                            text-align: center;
                            width: calc(100% /3);
                        }
                    }
                }
            }

            .contact-right {
                width: 100%;

                form {

                    input,
                    textarea {
                        font-size: 2.4vw;
                    }

                    button {
                        font-size: 2.5vw;
                        padding: 3%;
                        border-radius: 5px;
                    }
                }
            }

        }

        .contact-map {
            margin-bottom: 15%;
        }
    }

    // footer
    #footer {
        background-color: rgba(87, 189, 175, .1);
        background-image: none;
        margin-top: -5%;
        padding-top: 5%;

        .footer-bg {
            display: none;
        }

        footer {
            flex-wrap: wrap;

            .footer-content {
                width: 100% !important;
            }

            .footer-content {
                h6 {
                    font-size: 2.5vw;
                }
            }

            .left {
                .footer-left-body {
                    h4 {
                        font-size: 3vw;
                    }

                    p {
                        font-size: 2.5vw;
                    }
                }

                img {
                    width: 50%;
                    margin-bottom: 0;
                }
            }

            .right {
                h3 {
                    font-size: 3.5vw;
                }

                .footer-follow {
                    margin-bottom: 5%;
                }
            }
        }

        .copyright {
            font-size: 2vw;
        }
    }

    // privacy policy
    .privacy {
        margin-top: 23%;

        h3 {
            font-size: 2.3vw;
        }

        p,
        li {
            font-size: 1.8vw;
        }
    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {

    // header
    .header {
        img {
            width: 30%;
        }

        .menu-bar {
            display: none;

        }

        .menu-icon {
            display: block;
        }
    }

    //banner
    .banner {
        margin-top: 15%;
    }

    // slider
    .slider {
        margin-top: 15%;
    }

    // about
    .about-section {
        .about-body {
            flex-wrap: wrap;

            .about-left {
                display: none;
            }

            .about-right {
                padding: 0;
                width: 100%;

                span {
                    font-size: 3vw;
                    margin-bottom: 3px;
                }

                h2 {
                    font-size: 3.5vw;
                    padding-bottom: 10px;
                }

                .text {
                    font-size: 2.3vw;
                    margin-bottom: 10px;
                }
            }
        }
    }

    .about-page {
        .about-story {
            flex-direction: column;

            .story-content {
                width: 100%;

                h2 {
                    font-size: 3.5vw;
                }

                p {
                    font-size: 3vw;
                }
            }

            .story-img {
                width: 100%;
                margin-bottom: 7%;

                img {
                    padding: 0;
                }
            }
        }

        .about-values {
            .values-content {
                @include flex;
                flex-direction: column;
                margin-top: 15%;

                .values-card {
                    margin: 5% 0;

                    p {
                        font-size: 12px;
                        width: 60%;
                        height: 13vh;
                        padding: 2%;
                    }

                    &:hover {

                        &:nth-child(odd) {
                            .values-svg {
                                transform: translate(60%, -50%);
                            }
                        }

                        .values-svg {
                            transform: translate(-50%, -50%);
                        }

                    }

                    .values-svg {

                        width: 100px;
                        height: 100px;

                        svg {
                            width: 100px;
                            height: 100px;
                        }

                        h3 {
                            font-size: 16px;
                            height: 40%;

                        }
                    }


                    &:nth-child(1) {
                        .values-svg {
                            left: 20%;

                            svg {

                                path {
                                    fill: #8B3A3A;
                                }
                            }

                            h3 {
                                top: 120%;
                            }
                        }

                        p {
                            top: -40%;
                            left: 40%;
                        }
                    }

                    &:nth-child(2) {
                        .values-svg {
                            left: 80%;

                            svg {
                                padding: 10%;

                                path {
                                    fill: #1D88EA;
                                }
                            }

                            h3 {
                                top: 120%;
                            }

                        }

                        p {
                            top: -30%;
                            left: 0;
                        }
                    }


                    &:nth-child(3) {
                        .values-svg {
                            left: 20%;

                            svg {
                                path {
                                    fill: #FF4D4D;
                                }
                            }

                            h3 {
                                top: 120%;
                            }
                        }

                        p {
                            top: -30%;
                            left: 40%;
                        }
                    }


                    &:nth-child(4) {
                        .values-svg {
                            left: 80%;

                            svg {
                                path {
                                    fill: #ECAC57;
                                }
                            }

                            h3 {
                                top: 120%;
                            }
                        }

                        p {
                            top: -30%;
                            left: 0;
                        }
                    }

                    &:nth-child(5) {
                        .values-svg {
                            left: 20%;

                            svg {
                                path {
                                    fill: #6E8B3D;
                                }
                            }

                            h3 {
                                top: 120%;
                            }
                        }

                        p {
                            top: -20%;
                            left: 40%;
                        }
                    }

                }
            }

        }

        .team {
            .team-member {
                h4 {
                    font-size: 3vw;
                }

                p {
                    font-size: 2.5vw;
                }
            }

            .col-md-3 {
                width: calc(100% / 2);
            }
        }


    }

    //services
    .services {
        .services-body {
            flex-wrap: wrap;
            margin: 0;

            .services-card {
                width: 100%;
                margin: 5%;
                display: block;

                &:nth-child(2) {
                    margin: 0 5%;
                }

                h3 {
                    font-size: 4.5vw;
                    width: 90%;
                }

                &:hover {
                    width: 100%;

                }
            }
        }
    }

    .service-page {
        #service-section {
            #case-study{
             .case-study-card{
                 p{
                     font-size: 4vw;
                 }
                 &:hover{
                     li{
                         font-size: 2.5vw;
                     }
                 }
                
             }
            }
         }

        #service-service {
            .service-service {
                flex-wrap: wrap;

                .service-service-body {
                    width: 100%;
                    margin-bottom: 3%;

                    .service-service-text {
                        h4 {
                            font-size: 3.5vw;
                        }

                        ul {
                            li {
                                font-size: 3.3vw;
                            }
                        }
                    }

                    button {
                        width: 80px;
                        height: 80px;
                        font-size: 2.5vw;
                    }
                }
            }
        }

        #why-us {
            .features-area {
                .equal-height {
                    &::after {
                        display: none;
                    }
                }

                .col-md-4 {
                    width: 100%;
                }

                // .row {
                //     flex-wrap: wrap !important;
                // }

                .item {
                    padding: 5% 0;

                    .info {
                        h4 {
                            font-size: 3.5vw;
                        }

                        ul {
                            li {
                                font-size: 2.8vw;
                            }
                        }
                    }

                }
            }
        }


        #case-study {
            margin-bottom: 10%;

            .case-study {
                flex-wrap: wrap;

                .case-study-card {
                    width: 100%;

                    .case-study-card-caption {
                        h4 {
                            font-size: 4vw;
                        }

                        p {
                            font-size: 5vw;
                        }

                        .case-study-hidden {
                            font-size: 2.5vw;
                        }
                    }
                }
            }
        }
    }

    //map
    .map {
        .map-content {
            flex-wrap: wrap;

            button {
                width: 80%;
                font-size: 2.5vw;
                padding: 1%;
                margin: 2% 0;
            }
        }

        .map-img {
            flex-wrap: wrap;
            padding: 0;

            .panelist {
                width: 100%;

                h3 {
                    font-size: 3vw;
                    text-align: center;
                    margin-bottom: 5%;
                }

                ul {
                    margin: 5% 0;
                    margin-left: 0;
                    @include flex;
                    flex-wrap: wrap;
                    margin: 0 5%;

                    li {
                        font-size: 2.8vw;
                        width: 33%;

                        .post-it {
                            border-radius: 0 0 0 10px/15px;
                            height: 70px;
                            left: -90%;
                            padding: 3px;
                            width: 70px;

                            p {
                                font-size: 12px;
                                margin-bottom: -8px;
                            }

                            h5 {
                                font-size: 10px;
                            }
                        }

                        .post1 {
                            top: 630%;
                            left: 10%;
                        }

                        .post2 {
                            top: 1200%;
                            left: -35%;
                        }

                        .post3 {
                            top: 740%;
                            left: -70%;
                        }

                        .post4 {
                            top: 730%;
                            left: 155%;
                        }

                        .post5 {
                            top: 730%;
                            left: 90%;
                        }

                        .post6 {
                            top: 1130%;
                            left: 50%;
                        }
                    }


                }
            }

            .map-right {
                width: 100%;
                margin: 5% 0;

                .map-pin {
                    svg {
                        height: 10px;
                        width: 10px;
                    }

                    .post-it {
                        border-radius: 0 0 0 10px/15px;
                        height: 60px;
                        left: -200%;
                        padding: 1px;
                        width: 60px;
                    }
                }

                .pin1 {
                    left: 15%;
                    top: 15%;
                }

                .pin2 {
                    left: 50%;
                    top: 25%;
                }

                .pin3 {
                    bottom: 30%;
                    left: 30%;
                }

                .pin4 {
                    left: 57%;
                    top: 40%;
                }

                .pin5 {
                    right: 30%;
                    top: 40%;
                }

                .pin6 {
                    bottom: 20%;
                    right: 12%;
                }
            }
        }
    }

    //achievements
    .achievements {
        .a-card p {
            font-size: 2vw;
        }
    }

    // why-us
    #whyus {
        .why-card {

            &:first-child {
                padding: 0;
                margin-top: 5%;

                .why-card-img {
                    img {
                        width: calc(100% / 4.3);
                        padding: 0 5%;
                    }
                }

                .why-card-heading {
                    h3 {
                        width: calc(100% / 4.3);
                        font-size: 1.5vw;
                    }
                }
            }

            &:last-child {


                .why-card-body {
                    padding: 3% 5%;

                    p {
                        font-size: 2.5vw;
                    }
                }
            }
        }
    }


    // news
    .news {
        .news-body {
            flex-wrap: wrap;

            .news-body-content {
                width: 100%;

                .news-content {
                    h3 {
                        font-size: 4vw;
                    }


                }

                .news-content-overlay {
                    p {
                        font-size: 3.9vw;
                    }

                    a {
                        font-size: 3vw;
                    }
                }
            }
        }
    }

    //contact
    .contact-page {
        .contact-body {
            flex-wrap: wrap;


            .contact-left {
                width: 100%;
                flex-direction: row;
                flex-wrap: wrap;

                .contact-left-body {
                    flex-direction: column;
                    width: 48%;
                    margin: 1%;

                    svg {
                        width: 30px;
                        height: 30px;
                        display: block;
                        margin: 5% auto;
                    }
                }

                .contact-left-caption {
                    margin-left: 0;
                    margin: auto;

                    a {
                        font-size: 2vw;
                    }
                }

                .location {
                    width: 100%;

                    .svg {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .contact-left-caption {
                        flex-direction: row;

                        a {
                            margin: 0 2%;
                            text-align: center;
                            width: calc(100% /3);
                        }
                    }
                }
            }

            .contact-right {
                width: 100%;

                form {

                    input,
                    textarea {
                        font-size: 2.4vw;
                    }

                    button {
                        font-size: 2.5vw;
                        padding: 3%;
                        border-radius: 5px;
                    }
                }
            }

        }

        .contact-map {
            margin-bottom: 15%;
        }
    }

    // footer
    #footer {
        background-color: rgba(87, 189, 175, .1);
        background-image: none;
        margin-top: -5%;
        padding-top: 5%;

        .footer-bg {
            display: none;
        }

        footer {
            flex-wrap: wrap;

            .footer-content {
                width: 100% !important;
            }

            .footer-content {
                h6 {
                    font-size: 2.5vw;
                }
            }

            .left {
                .footer-left-body {
                    h4 {
                        font-size: 3vw;
                    }

                    p {
                        font-size: 2.5vw;
                    }
                }

                img {
                    width: 50%;
                    margin-bottom: 0;
                }
            }

            .right {
                h3 {
                    font-size: 3.5vw;
                }

                .footer-follow {
                    margin-bottom: 5%;
                }
            }
        }

        .copyright {
            font-size: 2vw;
        }
    }

    // privacy policy
    .privacy {
        margin-top: 23%;

        h3 {
            font-size: 2.3vw;
        }

        p,
        li {
            font-size: 1.8vw;
        }
    }

}

@media (min-width: 768px) and (max-width: $lg) and (orientation: landscape) {

    // header
    .header {
        img {
            width: 20%;
        }

        .menu-bar {
            display: none;

        }

        .menu-icon {
            display: block;
        }
    }

    // slider
    .slider {
        margin-top: 10%;
    }

    //service
    .service-page {
        #service-section {
            .service-section {
                .service-section-content {
                    svg {
                        width: 25px;
                        height: 25px;
                        margin: 2%;
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                }
            }
        }

        #why-us {
            margin-bottom: 10%;
        }


        #case-study {
            margin-bottom: 10%;

            .case-study {

                .case-study-card {

                    .case-study-card-caption {


                        .case-study-hidden {
                            font-size: 1.5vw;
                        }
                    }
                }
            }
        }
    }

    // about
    .about-page {

        .about-values {

            .values-content {

                .values-card {

                    p {
                        width: 180%;
                        height: 18vh;
                        padding: 5%;
                    }

                    &:hover {

                        p {
                            display: block;
                            transition: .5s all ease;
                        }
                    }

                    .values-svg {

                        width: 100px;
                        height: 100px;


                        svg {
                            width: 100px;
                            height: 100px;

                        }
                    }


                    &:nth-child(1) {
                        .values-svg {
                            top: 180%;

                            h3 {
                                top: -13%;
                                left: 50%;
                            }

                            svg {

                                path {
                                    fill: #8B3A3A;
                                }
                            }
                        }

                        p {
                            top: -100%;
                            left: -40%;
                        }
                    }

                    &:nth-child(2) {
                        .values-svg {
                            h3 {
                                bottom: -44%;
                                left: 50%;
                            }

                            svg {
                                padding: 10%;

                                path {
                                    fill: #1D88EA;
                                }
                            }
                        }

                        p {
                            top: 150%;
                            left: -25%;
                        }
                    }


                    &:nth-child(3) {
                        .values-svg {
                            top: 180%;


                            h3 {
                                top: -13%;
                                left: 50%;
                            }

                            svg {
                                path {
                                    fill: #FF4D4D;
                                }
                            }
                        }

                        p {
                            top: -100%;
                            left: -40%;
                        }
                    }


                    &:nth-child(4) {
                        .values-svg {
                            h3 {
                                bottom: -44%;
                                left: 50%;
                            }

                            svg {
                                path {
                                    fill: #ECAC57;
                                }
                            }
                        }

                        p {
                            top: 150%;
                            left: -40%;
                        }
                    }

                    &:nth-child(5) {
                        .values-svg {
                            top: 180%;

                            h3 {
                                top: -13%;
                                left: 50%;
                            }

                            svg {
                                path {
                                    fill: #6E8B3D;
                                }
                            }
                        }

                        p {
                            top: -100%;
                            left: -40%;
                        }
                    }

                }
            }

        }
    }

    //map
    .map {
        .map-img {

            .panelist {
                ul {
                    li {
                        .post-it {
                            border-radius: 0 0 0 10px/15px;
                            height: 70px;
                            left: -90%;
                            padding: 3px;
                            width: 70px;

                            p {
                                font-size: 12px;
                                margin-bottom: -8px;
                            }

                            h5 {
                                font-size: 10px;
                            }
                        }

                        .post1 {
                            top: -90%;
                            left: 140%;
                        }

                        .post2 {
                            top: 450%;
                            left: 175%;
                        }

                        .post3 {
                            top: -220%;
                            left: 222%;
                        }

                        .post4 {
                            top: -120%;
                            left: 240%;
                        }

                        .post5 {
                            top: -240%;
                            left: 260%;
                        }

                        .post6 {
                            top: 100%;
                            left: 302%;
                        }
                    }
                }
            }

            .map-right {
                .map-pin {
                    svg {
                        height: 15px;
                        width: 15px;
                    }


                }

                .pin1 {
                    left: 15%;
                    top: 15%;
                }

                .pin2 {
                    left: 50%;
                    top: 25%;
                }

                .pin3 {
                    bottom: 30%;
                    left: 30%;
                }

                .pin4 {
                    left: 57%;
                    top: 40%;
                }

                .pin5 {
                    right: 30%;
                    top: 40%;
                }

                .pin6 {
                    bottom: 20%;
                    right: 12%;
                }
            }
        }
    }

    // news
    .news {
        .news-body {
            .news-card {

                &:hover {
                    .news-content-overlay {
                        height: 170%;
                    }
                }
            }
        }
    }

    // contact
    .contact-page {
        .contact-body {
            .contact-left {
                .contact-left-body {
                    a {
                        font-size: 1.5vw;
                    }


                }

                .mail,
                .phone {
                    svg {
                        width: 30px;
                        height: 30px;
                    }
                }

                .location {
                    svg {
                        margin: 20% 0;

                    }
                }
            }

            .contact-right {
                form {

                    input,
                    textarea {
                        font-size: 2vw;
                    }

                    button {
                        font-size: 2.3vw;
                        border-radius: 5px;
                        padding: 3% 5%;
                        width: 60%;
                        background: $ttr;
                    }
                }

            }
        }

        .contact-map {
            margin-bottom: 10%;
        }
    }


    // footer
    #footer {
        margin-top: -40%;
    }

    // privacy policy
    .privacy {
        margin-top: 15%;

        h3 {
            font-size: 2.3vw;
        }

        p,
        li {
            font-size: 1.8vw;
        }
    }
}

@media (min-width: 768px) and (max-width: $lg) and (orientation: portrait) {

    // header
    .header {
        img {
            width: 20%;
        }

        .menu-bar {
            display: none;

        }

        .menu-icon {
            display: block;
        }
    }

    // slider
    .slider {
        margin-top: 10%;
    }

    // about
    .about-page {

        .about-values {

            .values-content {

                .values-card {

                    p {
                        width: 180%;
                        height: 18vh;
                        padding: 5%;
                    }

                    &:hover {

                        p {
                            display: block;
                            transition: .5s all ease;
                        }
                    }

                    .values-svg {

                        width: 100px;
                        height: 100px;


                        svg {
                            width: 100px;
                            height: 100px;

                        }
                    }


                    &:nth-child(1) {
                        .values-svg {
                            top: 180%;

                            h3 {
                                top: -13%;
                                left: 50%;
                            }

                            svg {

                                path {
                                    fill: #8B3A3A;
                                }
                            }
                        }

                        p {
                            top: -100%;
                            left: -40%;
                        }
                    }

                    &:nth-child(2) {
                        .values-svg {
                            h3 {
                                bottom: -44%;
                                left: 50%;
                            }

                            svg {
                                padding: 10%;

                                path {
                                    fill: #1D88EA;
                                }
                            }
                        }

                        p {
                            top: 150%;
                            left: -40%;
                        }
                    }


                    &:nth-child(3) {
                        .values-svg {
                            top: 180%;


                            h3 {
                                top: -13%;
                                left: 50%;
                            }

                            svg {
                                path {
                                    fill: #FF4D4D;
                                }
                            }
                        }

                        p {
                            top: -100%;
                            left: -40%;
                        }
                    }


                    &:nth-child(4) {
                        .values-svg {
                            h3 {
                                bottom: -44%;
                                left: 50%;
                            }

                            svg {
                                path {
                                    fill: #ECAC57;
                                }
                            }
                        }

                        p {
                            top: 150%;
                            left: -40%;
                        }
                    }

                    &:nth-child(5) {
                        .values-svg {
                            top: 180%;

                            h3 {
                                top: -13%;
                                left: 50%;
                            }

                            svg {
                                path {
                                    fill: #6E8B3D;
                                }
                            }
                        }

                        p {
                            top: -100%;
                            left: -40%;
                        }
                    }

                }
            }

        }
    }

    //service
    .service-page {
        #service-section {
            .service-section {
                .service-section-content {
                    svg {
                        width: 25px;
                        height: 25px;
                        margin: 2%;
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                }
            }
        }

        #why-us {
            margin-bottom: 10%;
        }

        #case-study {
            margin-bottom: 10%;

            .case-study {

                .case-study-card {

                    .case-study-card-caption {
                        .case-study-hidden {
                            font-size: 1.5vw;
                        }
                    }
                }
            }
        }
    }

    //map
    .map {
        .map-img {

            .panelist {
                ul {
                    li {
                        .post-it {
                            border-radius: 0 0 0 10px/15px;
                            height: 70px;
                            left: -90%;
                            padding: 3px;
                            width: 70px;

                            p {
                                font-size: 12px;
                                margin-bottom: -8px;
                            }

                            h5 {
                                font-size: 10px;
                            }
                        }

                        .post1 {
                            top: -90%;
                            left: 140%;
                        }

                        .post2 {
                            top: 450%;
                            left: 175%;
                        }

                        .post3 {
                            top: -220%;
                            left: 222%;
                        }

                        .post4 {
                            top: -120%;
                            left: 240%;
                        }

                        .post5 {
                            top: -240%;
                            left: 260%;
                        }

                        .post6 {
                            top: 100%;
                            left: 302%;
                        }
                    }
                }
            }

            .map-right {
                .map-pin {
                    svg {
                        height: 15px;
                        width: 15px;
                    }

                    .post-it {
                        border-radius: 0 0 0 10px/15px;
                        height: 80px;
                        left: -200%;
                        padding: 1px;
                        width: 80px;
                    }
                }

                .pin1 {
                    left: 15%;
                    top: 15%;
                }

                .pin2 {
                    left: 50%;
                    top: 25%;
                }

                .pin3 {
                    bottom: 30%;
                    left: 30%;
                }

                .pin4 {
                    left: 57%;
                    top: 40%;
                }

                .pin5 {
                    right: 30%;
                    top: 40%;
                }

                .pin6 {
                    bottom: 20%;
                    right: 12%;
                }
            }
        }
    }

    // news
    .news {
        .news-body {
            .news-card {

                &:hover {
                    .news-content-overlay {
                        height: 170%;
                    }
                }
            }
        }
    }

    //footer
    #footer {
        margin-top: -25%;
    }

    // privacy policy
    .privacy {
        margin-top: 15%;

        h3 {
            font-size: 2.3vw;
        }

        p,
        li {
            font-size: 1.8vw;
        }
    }
}

@media (min-width: 1025px) and (max-width: $xl) {


    //map
    .map {
        .map-img {
            .panelist {
                ul {
                    li {
                        .post-it {
                            border-radius: 0 0 0 10px/15px;
                            height: 100px;
                            left: -90%;
                            padding: 3px;
                            width: 100px;

                            p {
                                font-size: 22px;
                                margin-top: 10px;
                                margin-bottom: 8px;
                            }

                            h5 {
                                font-size: 15px;
                            }
                        }

                        .post1 {
                            top: 90%;
                            left: 140%;
                        }

                        .post2 {
                            top: 450%;
                            left: 185%;
                        }

                        .post3 {
                            top: -220%;
                            left: 222%;
                        }

                        .post4 {
                            top: -70%;
                            left: 245%;
                        }

                        .post5 {
                            top: -245%;
                            left: 270%;
                        }

                        .post6 {
                            top: 100%;
                            left: 310%;
                        }
                    }
                }
            }

            .map-right {

                .map-pin {
                    .post-it {
                        border-radius: 0 0 0 10px/15px;
                        height: 100px;
                        left: -200%;
                        padding: 1px;
                        width: 100px;

                        p {
                            margin-top: .5em;

                        }
                    }
                }


            }
        }
    }



}

@media (min-width: 1281px) and (max-width: $xl) {

    // about
    .about-page {

        .about-values {
            margin-bottom: 30%;

            .values-content {

                .values-card {

                    p {
                        width: 200%;
                        height: auto;
                        padding: 5%;
                        font-size: 1.25vw;
                    }

                    &:hover {
                        &:nth-child(odd) {
                            .values-svg {
                                transform: translate(0, 80%)scale(1.2);
                                transition: .5s all ease;
                                top: 100%;
                                left: 0;
                            }
                        }



                        p {
                            display: block;
                            transition: .5s all ease;
                        }
                    }

                    .values-svg {

                        width: 150px;
                        height: 150px;


                        svg {
                            width: 150px;
                            height: 150px;

                        }

                        h3 {
                            width: 200px;
                        }
                    }


                    &:nth-child(1) {
                        .values-svg {
                            top: 250%;

                            h3 {
                                top: -13%;
                                left: 50%;
                            }

                            svg {

                                path {
                                    fill: #8B3A3A;
                                }
                            }
                        }

                        p {
                            top: -60%;
                            left: -50%;
                        }
                    }

                    &:nth-child(2) {
                        .values-svg {
                            h3 {
                                bottom: -44%;
                                left: 50%;
                            }

                            svg {
                                padding: 10%;

                                path {
                                    fill: #1D88EA;
                                }
                            }
                        }

                        p {
                            top: 150%;
                            left: -50%;
                        }
                    }


                    &:nth-child(3) {
                        .values-svg {
                            top: 250%;


                            h3 {
                                top: -13%;
                                left: 50%;
                            }

                            svg {
                                path {
                                    fill: #FF4D4D;
                                }
                            }
                        }

                        p {
                            top: -40%;
                            left: -50%;
                        }
                    }


                    &:nth-child(4) {
                        .values-svg {
                            h3 {
                                bottom: -44%;
                                left: 50%;
                            }

                            svg {
                                path {
                                    fill: #ECAC57;
                                }
                            }
                        }

                        p {
                            top: 150%;
                            left: -50%;
                        }
                    }

                    &:nth-child(5) {
                        .values-svg {
                            top: 250%;

                            h3 {
                                top: -13%;
                                left: 50%;
                            }

                            svg {
                                path {
                                    fill: #6E8B3D;
                                }
                            }
                        }

                        p {
                            top: -15%;
                            left: -50%;
                        }
                    }

                }
            }

        }
    }


}

@media (min-width: 1025px) and (max-width: 1281px) {

    // about
    .about-page {

        .about-values {
            margin-bottom: 30%;

            .values-content {

                .values-card {

                    p {
                        width: 200%;
                        padding: 5%;
                        font-size: 1.1vw;
                    }

                    &:hover {
                        &:nth-child(odd) {
                            .values-svg {
                                transform: translate(0, 80%)scale(1.2);
                                transition: .5s all ease;
                                top: 100%;
                                left: 0;
                            }
                        }



                        p {
                            display: block;
                            transition: .5s all ease;
                        }
                    }

                    .values-svg {

                        width: 120px;
                        height: 120px;


                        svg {
                            width: 120px;
                            height: 120px;

                        }
                    }


                    &:nth-child(1) {
                        .values-svg {
                            top: 250%;

                            h3 {
                                top: -13%;
                                left: 50%;
                            }

                            svg {

                                path {
                                    fill: #8B3A3A;
                                }
                            }
                        }

                        p {
                            top: -60%;
                            left: -50%;
                        }
                    }

                    &:nth-child(2) {
                        .values-svg {
                            h3 {
                                bottom: -44%;
                                left: 50%;
                            }

                            svg {
                                padding: 10%;

                                path {
                                    fill: #1D88EA;
                                }
                            }
                        }

                        p {
                            top: 150%;
                            left: -50%;
                        }
                    }


                    &:nth-child(3) {
                        .values-svg {
                            top: 250%;


                            h3 {
                                top: -13%;
                                left: 50%;
                            }

                            svg {
                                path {
                                    fill: #FF4D4D;
                                }
                            }
                        }

                        p {
                            top: -20%;
                            left: -50%;
                        }
                    }


                    &:nth-child(4) {
                        .values-svg {
                            h3 {
                                bottom: -44%;
                                left: 50%;
                            }

                            svg {
                                path {
                                    fill: #ECAC57;
                                }
                            }
                        }

                        p {
                            top: 150%;
                            left: -50%;
                        }
                    }

                    &:nth-child(5) {
                        .values-svg {
                            top: 250%;

                            h3 {
                                top: -13%;
                                left: 50%;
                            }

                            svg {
                                path {
                                    fill: #6E8B3D;
                                }
                            }
                        }

                        p {
                            top: 0;
                            left: -50%;
                        }
                    }

                }
            }

        }
    }



}