@import '../Sass/variables.scss';

$color_1: #fff;
$color_2: inherit;
$color_3: #000;
$color_4: #515151;
$color_5: #dbdbdb;
$color_6: #d2d1d1;
$color_7: #443ea2;
$color_8: #875de5;

$font-family_2: "Nunito", sans-serif;
$background-color_1: #264959;
$background-color_2: transparent;
$background-color_3: #dfdfdf;
$background-color_4: rgba(236, 238, 239, 0.3);

/* =======================================================
register & Login
=========================================================*/
/* container and form */
/* inside the form user details */
/* submit button */
/* =======================================================
Admin Dashboard
=========================================================*/
/* dashboard-container */
/*=======================================================
EmployeeDashboardTitle
=======================================================*/

$main-blue: #71b7e6;
$main-purple: #9b59b6;
$main-grey: #ccc;
$sub-grey: #d9d9d9;
$family: 'Montserrat', sans-serif;
$font-family-sans-serif: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

.auth {
    .container {
        margin-top: 5%;
        padding: 2%;
        width: 40%;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    }

    .logo {
        width: 15%;
    }
}

.profile {
    .dashboard-container {
        form {
            display: flex;
            padding: 5%;
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
            margin-top: 5%;

            div {
                display: flex;
                flex-direction: column;
                margin: 1%;

                &:first-child {
                    width: 30%;
                }

                &:last-child {
                    width: 70%;
                }

                input {
                    width: 100%;
                    margin: 1% 0;
                    padding: 1% 3%;
                    outline: none;
                    font-size: 1.2vw;
                }

                label {
                    font-size: 1.4vw;
                    margin-top: 4%;
                }

            }

            button {
                height: 40px;
                width: 100%;
                font-size: 1.4vw;
                border: none;
                background: $ttr;
                color: #fff;
            }
        }

    }
}

.admin {
    .dashboard-app {
        img {
            width: 100%;
        }
    }
}

.container {
    max-width: 700px;
    width: 100%;
    background: $ttrtransparent;
    padding: 25px 30px;
    border-radius: 5px;

    .title {
        font-size: 25px;
        font-weight: 500;
        position: relative;
        padding-bottom: 1em;
    }

    form {
        .user__details {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: space-between;
            margin: 20px 0 12px 0;
        }
    }
}

form {
    .user__details {
        .input__box {
            width: 100%;
            margin-bottom: 15px;
        }
    }

    .button {
        height: 45px;
        margin: 45px 0;

        input {
            height: 100%;
            width: 100%;
            outline: none;
            color: $color_1;
            border: none;
            font-size: 18px;
            font-weight: 500;
            border-radius: 5px;
            background: $ttr;
            transition: all 0.3s ease;
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);

            &:hover {
                background: linear-gradient(-135deg, var($main-blue), var($main-purple));
            }
        }
    }
}

.user__details {
    .input__box {
        .details {
            font-weight: 500;
            margin-bottom: 5px;
            display: block;
        }

        input {
            height: 45px;
            width: 100%;
            outline: none;
            border-radius: 5px;
            border: 1px solid var($main-grey);
            padding-left: 15px;
            font-size: 16px;
            border-bottom-width: 2px;
            transition: all 0.3s ease;

            &:focus {
                border-color: $main-purple;
            }

            &:valid {
                border-color: $main-purple;
            }
        }
    }
}



.dashboard {
    display: flex;
    min-height: 100vh;

    .dashboard-app {
        width: 75%;
        padding: 0 3% 5% 3%;
        font-family: $family;
        color: $color_3;
        margin-left: auto;

        .dashboard-container {
            text-align: center;
            margin-top: 5%;


        }

        .dashboard-content {

            table {
                width: 100%;
                border-collapse: collapse;
                margin: 50px auto;
            }

            /* Zebra striping */
            tr:nth-of-type(odd) {
                background: #eee;
            }

            th {
                background: $darkBlue;
                color: white;
                font-weight: bold;
            }

            td,
            th {
                padding: 10px;
                border: 1px solid #ccc;
                text-align: left;
                font-size: 13px;
            }

            /* 
            Max width before this PARTICULAR table gets nasty
            This query will take effect for any screen smaller than 760px
            and also iPads specifically.
            */
            @media only screen and (max-width: 760px),
            (min-device-width: 768px) and (max-device-width: 1024px) {

                table {
                    width: 100%;
                }

                /* Force table to not be like tables anymore */
                table,
                thead,
                tbody,
                th,
                td,
                tr {
                    display: block;
                }

                /* Hide table headers (but not display: none;, for accessibility) */
                thead tr {
                    position: absolute;
                    top: -9999px;
                    left: -9999px;
                }

                tr {
                    border: 1px solid #ccc;
                }

                td {
                    /* Behave  like a "row" */
                    border: none;
                    border-bottom: 1px solid #eee;
                    position: relative;
                    padding-left: 50%;
                }

                td:before {
                    /* Now like a table header */
                    position: absolute;
                    /* Top/left values mimic padding */
                    top: 6px;
                    left: 6px;
                    width: 45%;
                    padding-right: 10px;
                    white-space: nowrap;
                    /* Label the data */
                    content: attr(data-column);

                    color: #000;
                    font-weight: bold;
                }

            }
        }
    }

    .dashboard-nav {
        width: 25%;
        background: rgb(67, 179, 191);
        background: radial-gradient(circle, rgba(67, 179, 191, 1) 0%, rgba(60, 114, 111, 1) 100%);
        padding: 2%;
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        // border-right: 3px solid $darkBlue;

        header {
            display: flex;
            justify-content: center;
            align-items: center;

            .menu-toggle {
                display: none;
                margin-right: auto;
            }

            .a-logo {
                background: #fff;
                padding: 5%;

                img {
                    width: 100%;
                }
            }
        }

        a {
            color: #fff;

            &:hover {
                text-decoration: none;
            }
        }

        .dashboard-nav-list {
            display: flex;
            flex-direction: column;
            margin-top: 15%;

            .dashboard-nav-item {
                min-height: 56px;
                padding: 5%;
                display: flex;
                align-items: center;
                letter-spacing: 0.02em;
                transition: ease-out 0.5s;
                border: 1px solid rgba(255, 255, 255, 0.4);
                border-radius: 10px;
                margin: 1% 0;

                svg {
                    fill: #fff;
                }

                &:hover {
                    background: rgba(255, 255, 255, 0.04);
                }
            }

            .logout {
                border: none;
                padding: 1% 0;
                position: fixed;
                width: 21%;
                bottom: 2%;
                background: transparent;
                // border: 1px solid #fff;
                color: #fff;
                font-size: 1.5vw;
                border-top: 1px solid #fff;

                svg {
                    fill: #fff;
                }
            }

            .accordion {
                border: none !important;

                &:focus {
                    outline: 0 !important;
                }
            }

            .accordion-button {
                &:focus {
                    outline: 0 !important;
                }

                &::after {
                    background-color: $background-color_2 !important;
                    margin-left: auto !important;
                    display: none;
                }
            }

            .accordion-header {
                &:focus {
                    outline: 0 !important;
                }

                button {
                    background: transparent !important;
                    color: #fff !important;
                    padding: 0;
                }
            }

            .accordion-body {
                display: flex;
                flex-direction: column;
            }

            .accordion-item {
                background: transparent !important;
                border: none !important;
                color: #fff !important;
                font-size: 1.1vw;
            }
        }


    }

}

@media only screen and (max-width: 584px) {
    .container {
        max-width: 100%;

        form {
            .user__details {
                max-height: 300px;
                overflow-y: scroll;
            }
        }
    }

    form {
        .user__details {
            .input__box {
                margin-bottom: 15px;
                width: 100%;
            }
        }

        .gender__details {
            .category {
                width: 100%;
            }
        }
    }

    .user__details {
        &::-webkit-scrollbar {
            width: 0;
        }
    }
}

@media (max-width: 992px) {
    .dashboard-nav {
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 1070;

        header {
            .menu-toggle {
                display: flex;
            }
        }
    }

    .dashboard-nav.mobile-show {
        display: block;
    }
}

@media (min-width: 992px) {
    .dashboard-toolbar {
        left: 238px;
    }

    .dashboard-compact {
        .dashboard-toolbar {
            left: 0;
        }
    }
}