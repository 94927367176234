@import 'variables.scss';
@import 'mixins.scss';
@import 'animations.scss';
@import url('https://fonts.googleapis.com/css2?family=Tilt+Prism&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

h1 {
    @include headline;
}

a {
    text-decoration: none;
    color: inherit;
}

li {
    list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    text-transform: capitalize;
}

ul {
    padding-left: 0;
}

.service-headline {
    @include Headline;
    margin-top: 2%;
    margin-bottom: 2%;
}

.banner {
    position: relative;
    margin-bottom: 5%;
    margin-top: 5%;

    img {
        width: 100%;
        // filter: brightness(70%);
    }

    .banner-caption {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: rgba($color: #000000, $alpha: 0.6);
        height: 100%;
        width: 100%;
        @include flex;
        flex-direction: column;
        align-items: center;
        text-shadow: 2px 2px 2px #000;

        h3 {
            -webkit-animation: swivel-vertical-double 1s ease-out 0s 1;
            animation: swivel-vertical-double 1s ease-out 0s 1;
            color: #fff;
            font-family: Bebas Neue, sans-serif;
            font-size: 5.5vw;
            font-weight: 400;
            margin-bottom: 2%;
            text-align: center;
        }

        p {
            text-align: center;
            color: #fff;
            font-size: 2vw;
            margin: 0;
            text-transform: capitalize;
        }
    }
}


.service-banner {
    img {
        filter: brightness(30%);
    }

    .banner-caption {
        top: 70%;
        height: 90%;
        background: transparent;
    }
}

.contact {
    img {
        filter: brightness(80%);
    }
}

//=================================================================================
//   Header
//=================================================================================

.header {
    align-items: center;
    background: #fff;
    box-shadow: 2px 2px 4px #445454;
    display: flex;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;

    img {
        background-color: hsla(0, 0%, 100%, .2);
        margin: 0 0 0 5%;
        width: 15%;
    }

    .menu-bar {
        align-items: center;
        border-radius: 25px;
        display: flex;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin: 0 0 0 25%;
        padding: 0 10px;

        a {
            margin: 0 2%;
            text-decoration: none;
            color: inherit;
            border-radius: 40px;


            &:hover {
                background: $mainGradient;
                transition: 500ms all ease;
                color: #fff;
                transform: scale(1.1);
            }

            &:last-child {
                background: $mainGradient;
                color: #fff;
                border-radius: 40px;

            }
        }

        li {
            cursor: pointer;
            font-family: sans-serif;
            font-size: 1vw;
            font-weight: 600;
            list-style: none;
            padding: 12px 16px;
            position: relative;
            text-transform: uppercase;
            white-space: nowrap;



            &:before {
                border-radius: 25px;
                content: " ";
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                transition: .2s;
                width: 100%;
                z-index: -1;
            }
        }
    }

    .menu-icon {
        display: none;
        margin-left: auto;
        margin-right: 5%;
        width: 30px;
    }
}

.header-mobile {
    -webkit-animation: slide-in-right .5s ease-out 0s 1;
    animation: slide-in-right .5s ease-out 0s 1;
    background-color: #fff;
    display: none;
    height: 100%;
    padding: 5%;
    position: fixed;
    right: 0;
    top: 0;
    width: 50%;
    z-index: 1500;

    img {
        display: block;
        margin-left: auto;
        margin-right: 10%;
        width: 20px;
    }

    .menu-bar {
        align-items: flex-start;
        border-radius: 25px;
        display: flex;
        flex-direction: column;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding: 0 10px;

        a {
            margin: 0 2%;
        }

        li {
            cursor: pointer;
            font-family: sans-serif;
            font-size: 10px;
            font-weight: 600;
            list-style: none;
            padding: 12px 16px;
            position: relative;
            text-transform: uppercase;
            white-space: nowrap;
        }
    }


}

//=================================================================================
//   Slider
//=================================================================================
.slider {
    margin-top: 8%;
    position: relative;

    .carousel-status,
    .thumbs {
        display: none;
    }

    .caption {
        left: 50%;
        padding-bottom: 5%;
        position: absolute;
        top: 60%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 80%;

        h3 {
            -webkit-animation: swivel-vertical-double 1s ease-out 0s 1;
            animation: swivel-vertical-double 1s ease-out 0s 1;
            color: #fff;
            font-family: Bebas Neue, sans-serif;
            font-size: 4.3vw;
            font-weight: 400;
            margin-bottom: 5%;
            text-align: center;
            text-shadow: 2px 2px 2px #000;

        }

        .slider-btn {
            align-items: stretch;
            display: flex;
            display: inline;
            justify-content: center;
            margin: 5%;
        }

        .slider-btn button {
            background: $mainGradient;
            border: none;
            border-radius: 5px;
            color: #fff;
            font-size: 1.3vw;
            font-weight: 700;
            letter-spacing: 1px;
            margin: 0 5%;
            padding: 2%;
            text-transform: uppercase;
            width: 35%;
        }
    }

    video {
        filter: brightness(40%);
    }

    .modal-content {
        background: $mainGradient;

        .modal-header {
            .modal-title {
                color: #fff;
            }

            .btn-close {
                background-color: #fff;
            }
        }

        .modal-body {
            form {
                display: flex;
                flex-wrap: wrap;
            }

            input,
            select {
                background: #fff;
                border: 1px solid #fff;
                border-radius: 5px;
                color: #3c726f;
                margin: 1%;
                padding: 1% 3%;
                width: 48%;
            }

            select {
                background: #fff url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E") no-repeat right 0.3em center/15px 15px !important;
            }
        }

        .modal-footer {
            button {
                border: 1px solid #fff;
                color: #fff;
            }
        }
    }
}

// ==========================================================
// About
// ==========================================================

.about-section {
    padding: 0 5%;

    .about-body {
        @include flex;
        margin: 5% 0;

        .about-left-hidden {
            display: none;
            margin-bottom: 5%;
            border-radius: 10px;

            img {
                width: 100%;
                border-radius: 10px;
                filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.5));
            }
        }

        .about-left {
            background: #fff;
            padding: 5%;
            position: relative;
            width: 50%;

            &:before {
                background: $mainGradient;
                content: "";
                height: 89%;
                left: 3%;
                position: absolute;
                top: 0;
                width: 85%;
                z-index: 0;
            }

            .about-left-after {
                background: #fff;
                content: "";
                height: 67%;
                left: 10%;
                position: absolute;
                top: 10%;
                width: 70%;
                z-index: 0;
            }

            img {
                border-radius: 5px;
                box-shadow: 0 30px 50px rgba(8, 13, 62, .15);
                height: 80%;
                left: 15%;
                object-fit: cover;
                position: absolute;
                top: 15%;
                width: 80%;
            }
        }

        .about-right {
            padding: 0 5%;
            width: 50%;

            span {
                color: #00aeef;
                display: block;
                font-size: 1.3vw;
                font-weight: 500;
                line-height: 24px;
                margin-bottom: 15px;
            }

            h2 {
                color: #222;
                display: block;
                font-size: 2vw;
                font-weight: 600;
                line-height: 1.28em;
                padding-bottom: 18px;
                position: relative;

                &:before {
                    background-color: #d1d2d6;
                    bottom: 0;
                    content: "";
                    height: 3px;
                    left: 0;
                    position: absolute;
                    width: 50px;
                }
            }

            .text {
                color: #848484;
                font-size: 1.1vw;
                font-weight: 400;
                line-height: 160%;
                margin-bottom: 20px;
            }
        }
    }
}

.about-page {

    .story,
    .approach {
        flex-direction: row-reverse;
    }

    .about-story {
        @include flex;
        padding: 0 5% 5% 5%;

        .story-content {
            width: 50%;

            h2 {
                font-size: 2vw;
                text-transform: capitalize;
                color: $darkBlue;
                margin-bottom: 5%;
                text-align: center;
            }

            p {
                font-size: 1.3vw;
                color: rgba($color: #000000, $alpha: 0.6);
            }

        }

        .story-img {
            width: 50%;
            border-radius: 10px;
            position: relative;

            &:hover {
                img {
                    filter: brightness(50%);
                    transition: .5s all ease;

                }

                .square1 {
                    transform: scale(1.2) translateX(10%) translateY(-10%);
                    transition: .5s all ease;
                    border-color: #fff;
                }

                .square2 {
                    transform: scale(1.2) translateX(-10%) translateY(10%);
                    transition: .5s all ease;
                    border-color: #fff;

                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                padding: 0 5%;
                border-radius: 10px;
                filter: drop-shadow(5px 5px 5px $darkBlue) brightness(80%);
                // box-shadow: 2px 2px 5px $darkBlue;
            }

            .square1 {
                position: absolute;
                bottom: 8%;
                left: 8%;
                width: 80px;
                height: 80px;
                background: transparent;
                border-left: 8px solid rgba($color: #fff, $alpha: 0.5);
                border-bottom: 8px solid rgba($color: #fff, $alpha: 0.5);
                z-index: 2;
                border-radius: 2px;
            }

            .square2 {
                position: absolute;
                top: 8%;
                right: 8%;
                width: 80px;
                height: 80px;
                background: transparent;
                border-right: 8px solid rgba($color: #fff, $alpha: 0.5);
                border-top: 8px solid rgba($color: #fff, $alpha: 0.5);
                z-index: 2;
                border-radius: 2px;

            }
        }

    }

    .about-values {
        padding: $padding;
        margin-bottom: 25%;

        .values-content {
            @include flex;
            margin: 5% auto;


            .values-card {
                margin: 2% 4% 0 4%;
                position: relative;

                p {
                    display: none;
                    position: absolute;
                    top: -50%;
                    left: -20%;
                    font-size: 11px;
                    text-align: center;
                    width: 150%;
                    background: #fff;
                    padding: 5%;
                    color: #000;
                    border-radius: 5px;
                    box-shadow: 2px 2px 10px rgba($color: #000, $alpha: 0.2);
                }

                &:hover {
                    &:nth-child(odd) {
                        .values-svg {
                            transform: scale(1.2);
                            transition: .5s all ease;
                            top: 100%;
                            left: 0;
                        }
                    }

                    .values-svg {
                        transform: scale(1.2);
                        transition: .5s all ease;
                        top: 0;
                        left: 0;
                    }

                    p {
                        display: block;
                        transition: .5s all ease;
                    }
                }

                .values-svg {
                    position: relative;
                    box-shadow: 5px 5px 10px rgba($color: #000, $alpha: 0.2);
                    border-radius: 50%;
                    width: 150px;
                    height: 150px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                    svg {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 150px;
                        height: 150px;
                        padding: 20%;

                    }


                    h3 {
                        font-size: 1.5vw;
                        position: absolute;
                        width: 150px;
                        transform: translate(-50%, -50%);
                        background: $ttr;
                        color: #fff;
                        padding: 5% 10%;
                        border-radius: 10px 0 10px 0;
                        text-align: center;
                    }
                }


                &:nth-child(1) {
                    .values-svg {
                        top: 150%;

                        h3 {
                            top: -12%;
                            left: 50%;
                        }

                        svg {

                            path {
                                fill: #8B3A3A;
                            }
                        }
                    }

                }

                &:nth-child(2) {
                    .values-svg {
                        h3 {
                            bottom: -44%;
                            left: 50%;
                        }

                        svg {
                            padding: 10%;

                            path {
                                fill: #1D88EA;
                            }
                        }
                    }

                    p {
                        top: 150%;
                        left: -25%;
                    }
                }


                &:nth-child(3) {
                    .values-svg {
                        top: 150%;


                        h3 {
                            top: -12%;
                            left: 50%;
                        }

                        svg {
                            path {
                                fill: #FF4D4D;
                            }
                        }
                    }
                }


                &:nth-child(4) {
                    .values-svg {
                        h3 {
                            bottom: -44%;
                            left: 50%;
                        }

                        svg {
                            path {
                                fill: #ECAC57;
                            }
                        }
                    }

                    p {
                        top: 150%;
                        left: -25%;
                    }
                }

                &:nth-child(5) {
                    .values-svg {
                        top: 150%;


                        h3 {
                            top: -12%;
                            left: 50%;
                        }

                        svg {
                            path {
                                fill: #6E8B3D;
                            }
                        }
                    }
                }

            }
        }

    }

    // $color_1: #a5a5a5;
    // $color_2: transparent;
    // $color_3: #fff;
    // $color_4: rgba(49, 49, 49, .97);
    // $background-color_1: transparent;

    // .gray {
    //     color: $color_1;
    // }

    // .team {
    //     margin: 0 5% 5% 5%;
    // }

    // .team-member {
    //     margin: 15px 0;
    //     padding: 0;

    //     figure {
    //         position: relative;
    //         overflow: hidden;
    //         padding: 0;
    //         margin: 0;

    //         img {
    //             width: 100%;
    //             transform: scale(1) rotate(0) translateY(0);
    //             transition: all 0.4s ease-in-out;
    //         }

    //         &:hover {
    //             figcaption {
    //                 visibility: visible;
    //                 color: $color_3;
    //                 background: rgba($color: $darkBlue, $alpha: 0.8);
    //                 height: 100%;
    //                 transition: all 0.3s ease-in-out;

    //                 ul {
    //                     li {
    //                         a {
    //                             &:hover {
    //                                 color: $color_4;
    //                             }
    //                         }
    //                     }
    //                 }
    //             }

    //             img {
    //                 transform: scale(1.1) rotate(1deg) translateY(12px);
    //                 transition: all 0.4s ease-in-out;
    //             }
    //         }
    //     }

    //     figcaption {
    //         p {
    //             font-size: 16px;
    //         }

    //         ul {
    //             list-style: none;
    //             margin: 0;
    //             padding: 0;
    //             visibility: visible;
    //             transition: all 0.1s ease-in-out;

    //             li {
    //                 display: inline-block;
    //                 padding: 10px;

    //                 a {
    //                     svg {
    //                         width: 30px;
    //                         height: 30px;

    //                         path {
    //                             fill: #fff;
    //                         }
    //                     }
    //                 }
    //             }
    //         }

    //         padding: 50px;
    //         color: $color_2;
    //         background-color: $background-color_1;
    //         position: absolute;
    //         z-index: 996;
    //         bottom: 0;
    //         left: 0;
    //         width: 100%;
    //         height: 0;
    //         overflow: hidden;
    //         visibility: hidden;
    //         transition: all 0.3s ease-in-out;
    //     }

    //     h4 {
    //         margin: 10px 0 0;
    //         padding: 0;
    //         font-size: 2vw;
    //     }

    //     p {
    //         font-size: 1vw;
    //     }
    // }


}

// ======================================================================
// Services
// ======================================================================


.services {
    padding: 0 5%;

    .service-headline {
        @include Headline;
    }

    .services-body {
        align-items: stretch;
        display: flex;
        justify-content: center;
        margin: 5%;

        .services-card {
            position: relative;
            transition: width 1s;
            width: 33.3333333333%;

            &:nth-child(2) {
                margin: 0 2%;
            }

            &:hover {
                width: 40%;

                .overlay {
                    background: $ttrtransparent;
                }
            }

            img {
                -webkit-filter: brightness(50%);
                filter: brightness(50%);
                height: 100%;
                object-fit: cover;
                transition: all 1s ease;
                width: 100%;
            }

            .overlay {
                background: linear-gradient(50deg, rgba(67, 179, 191, .3), rgba(63, 146, 150, .3) 0, rgba(60, 114, 111, .3) 36%);
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }

            h3 {
                color: #fff;
                font-size: 1.8vw;
                left: 60%;
                position: absolute;
                text-transform: uppercase;
                top: 30%;
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                width: 100%;

                &:after {
                    background-color: #fff;
                    bottom: -30%;
                    content: "";
                    height: 3px;
                    left: 0;
                    position: absolute;
                    width: 50%;
                }
            }
        }
    }
}

.service-page {

    .service-headline {
        @include Headline;
        margin-top: 2%;
        margin-bottom: 2%;

    }

    #service-section {
        padding: 0 5% 0 5%;

        #case-study {
            padding: 0;
            margin-bottom: 0;

            .case-study-card {
                border-radius: 0;
                box-shadow: 2px 2px 5px rgba(0, 0, 0, .2);
                border: none;

                img {
                    border-radius: 0;

                }

                p {
                    font-size: 1.2vw;
                }

                &:hover {
                    width: 80%;
                    height: 100%;
                    transition: 1s all ease;

                    p {
                        display: none;
                    }

                    li {
                        font-size: 1.2vw;
                    }

                    .case-study-card-caption {
                        top: -8.3%;
                        border-radius: 0;
                    }

                }
            }
        }
    }


    #service-service {
        padding: $padding;

        .service-service {
            @include flex;

            .service-service-body {
                position: relative;
                margin: 1%;
                border-radius: 10px;

                img {
                    width: 100%;
                    height: 50%;
                    object-fit: cover;
                    z-index: 1;
                }

                button {
                    text-transform: capitalize;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    background: $ttr;
                    color: #fff;
                    border-radius: 100% 0 0 0;
                    width: 80px;
                    height: 80px;
                    border: none;
                    padding: 10px 0 0 10px;
                    z-index: 3;
                    font-size: 1.1vw;

                    &:hover {
                        transition: 500ms all ease;
                        width: 100px;
                        height: 100px;

                    }
                }

                .service-service-text {
                    background: #fff;
                    // position: absolute;
                    // bottom: -50%;
                    // left: 0;
                    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.5));
                    width: 100%;
                    height: 50%;

                    padding: 2%;
                    z-index: 2;


                    h4 {
                        font-size: 1.3vw;
                        // margin: 5% auto;
                        text-align: center;
                        padding: 3%;
                        border-radius: 40px;
                    }

                    ul {
                        padding-left: 15%;
                    }

                    li {
                        list-style-type: disc;
                        font-size: 1vw;
                    }
                }
            }
        }
    }

    #why-us {
        padding: $padding;

        .container{
            background: transparent;
            max-width: 100%;
        }

        .features-area {
            padding-top: 2%;

            .equal-height {
                &::after {
                    background: #e7e7e7 none repeat scroll 0 0;
                    content: "";
                    height: 100%;
                    position: absolute;
                    right: -1px;
                    top: 0;
                    width: 1px;

                    &:last-child(1) {
                        background: none;
                    }
                }
            }

            .item {
                padding: 7%;

                .icon {
                    background: #ffffff none repeat scroll 0 0;
                    border-radius: 50%;
                    box-shadow: 0 0 10px #cccccc;
                    color: #ffaf5a;
                    display: inline-block;
                    height: 100px;
                    line-height: 100px;
                    position: relative;
                    text-align: center;
                    width: 100px;
                    z-index: 1;
                    margin-bottom: 5%;

                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 60px;
                        height: 60px;

                    }
                }

                .info {
                    h4 {
                        font-weight: 600;
                        text-transform: capitalize;
                        font-size: 1.5vw;
                    }

                    ul {
                        margin-top: 5%;
                        padding-left: 15%;

                        li {
                            margin: 0;
                            text-align: left;
                            list-style-type: disc;
                            font-size: 1vw;
                            color: rgba(0, 0, 0, .7)
                        }
                    }
                }

            }
        }

    }

    #case-study {
        padding: $padding;
        margin-bottom: 5%;

        .case-study {
            padding-top: 2%;
            @include flex;

            .case-study-card {
                width: calc(100% / 3);
                margin: 1%;
                border-radius: 10px;
                border: 1px solid rgba($color: #00aeef, $alpha: 0.3);
                position: relative;

                img {
                    width: 100%;
                    border-radius: 10px 10px 0 0;

                }

                .case-study-card-caption {
                    margin: 5%;

                    h4 {
                        font-size: 1.4vw;
                        background: $ttr;
                        width: 30%;
                        color: #fff;
                        border-radius: 0 10px 0 10px;
                        padding: 1%;
                        text-align: center;
                        text-shadow: 2px 2px 2px rgba($color: #000, $alpha: 0.2);
                        text-transform: uppercase;

                    }

                    p {
                        text-transform: uppercase;
                        font-size: 1.5vw;
                        font-weight: bold;
                        margin: 5% 0;
                    }

                    .case-study-hidden {
                        display: none;
                    }

                }

                button {
                    text-transform: capitalize;
                    font-size: 1.2vw;
                    font-weight: bold;
                    margin: 5%;
                    border: none;
                    background: transparent;
                    font-weight: normal;
                    display: none;
                }

                &:hover {
                    .case-study-card-caption {
                        width: 100%;
                        padding: 5%;
                        height: 100%;
                        background: #fff;
                        transition: .5s all ease;
                        position: absolute;
                        top: -4.7%;
                        left: -5%;
                        border: 1px solid rgba($color: #00aeef, $alpha: 0.3);
                        border-radius: 10px;

                        .case-study-hidden {
                            display: block;
                        }
                    }
                }

            }
        }
    }

}

// ======================================================================
// map
// ======================================================================
.map {
    padding: 0 5%;
    margin-bottom: 5%;

    .map-headline {
        @include Headline;

    }

    .map-content {
        align-items: stretch;
        display: flex;
        justify-content: center;
        margin: 5% 0;

        button {
            background: $mainGradient;
            border: none;
            border-radius: 40px;
            color: #fff;
            font-size: 1.1vw;
            margin: 0 2%;
            padding: 1% 2%;
        }
    }

    .map-img {
        align-items: center;
        display: flex;
        padding: 0 5%;
        position: relative;

        .panelist {
            text-transform: capitalize;
            width: 30%;

            h3 {
                color: #43b3bf;
                font-size: 1.5vw;
            }

            ul {
                margin-left: 15%;

                li {
                    color: #3c726f;
                    font-size: 1.2vw;
                    list-style-type: circle;
                    margin: 2% 0;
                    text-transform: capitalize;
                    cursor: pointer;

                    &:hover {
                        font-weight: bolder;
                        transition: .2s all ease;

                        .post-it {
                            display: block;
                            animation: slide-in-down .2s ease-out 0s 1;
                        }
                    }

                    position: relative;

                    .post-it {
                        background: #43b3bf;
                        background: #3c726f;
                        border-radius: 0 0 0 20px/25px;
                        box-shadow: inset 0 -40px 40px rgba(0, 0, 0, .2), inset 0 25px 10px rgba(0, 0, 0, .2), 0 5px 6px 5px rgba(0, 0, 0, .2);
                        color: #fff;
                        display: none;
                        font-size: 19px;
                        height: 100px;

                        line-height: 1.7em;
                        overflow: hidden;
                        padding: 20px;
                        position: absolute;
                        text-align: center;
                        width: 100px;
                        z-index: 29;



                        p {
                            font-size: 1.8vw;
                            font-weight: 700;
                            margin-bottom: 3px;
                        }

                        h5 {
                            font-size: 1vw;
                            margin: 5px 0;
                            text-transform: capitalize;
                        }
                    }

                    .post1 {
                        top: 50%;
                        left: 140%;
                    }

                    .post2 {
                        top: 400%;
                        left: 180%;
                    }

                    .post3 {
                        top: -200%;
                        left: 220%;
                    }

                    .post4 {
                        top: -50%;
                        left: 240%;
                    }

                    .post5 {
                        top: -280%;
                        left: 265%;
                    }

                    .post6 {
                        top: 70%;
                        left: 305%;
                    }
                }
            }
        }

        .map-right {
            margin: auto;
            position: relative;
            width: 60%;

            img {
                display: block;
                margin: auto;
                width: 100%;
            }

            .map-pin {
                position: absolute;

                &:hover {
                    .post-it {
                        display: block;
                        animation: slide-in-down .2s ease-out 0s 1;

                    }
                }

                svg {
                    display: block;
                    height: 20px;
                    position: relative;
                    width: 20px;
                    z-index: 30;
                }


            }

            .pin1 {
                left: 15%;
                top: 30%;
            }

            .pin2 {
                left: 50%;
                top: 25%;
            }

            .pin3 {
                bottom: 30%;
                left: 33%;
            }

            .pin4 {
                left: 58%;
                top: 45%;
            }

            .pin5 {
                right: 29%;
                top: 40%;
            }

            .pin6 {
                bottom: 20%;
                right: 12%;
            }
        }
    }
}

// ======================================================================
// Achievements
// ======================================================================
.achievements {
    padding: $padding;

    align-items: stretch;
    background: $ttr;
    ;
    display: flex;
    justify-content: center;
    margin: 2% 0;
    padding: 2%;

    .a-card {
        color: #fff;
        margin: 0 2%;
        text-align: center;
        width: 25%;

        svg {
            fill: #fff;
            width: 50px;
        }

        h4 {
            display: flex;
            font-size: 2vw;
            justify-content: center;
            margin: 5% 0;
        }

        p {
            font-size: 1.2vw;
            text-transform: capitalize;
        }
    }
}

// ======================================================================
// Why Choose US
// ======================================================================

#whyus {
    padding: $padding;

    .why-headline {
        @include Headline;
    }

    .whyChooseUs {
        height: 80%;
        padding: 2%;
    }

    .why-card {

        &:first-child {
            padding: 5%;

            .why-card-img {
                @include flex;
                align-items: center;

                img {
                    width: calc(100% / 5);
                    padding: 0 5%;

                    &:hover {

                        transform: scale(1.3);
                        transition: 1s all ease;

                    }
                }


            }

            .why-card-heading {
                @include flex;
                // margin: 0 10%;
                align-items: center;

                h3 {
                    font-size: 1.2vw;
                    text-align: center;
                    width: calc(100% / 5);
                    padding: 2% 3%;
                }
            }
        }

        &:last-child {
            .why-card-body {
                background: $mainGradient;
                border-radius: 10px;
                color: #fff;
                margin: 0 auto 3% auto;
                padding: 2% 15%;
                text-align: center;
                width: 90%;

                h3 {
                    font-size: 2vw;
                    margin: 1em 0;
                }

                p {
                    font-size: 2vw;
                    margin: 0;
                }
            }
        }
    }
}

// ======================================================================
// News Headline
// ======================================================================
.news {
    padding: $padding;

    .news-headline {
        @include Headline;

    }

    .news-body {
        @include flex;
        padding-bottom: 5%;
        padding-top: 2%;

        .news-body-content {
            // height: 30vh;
            margin: 2%;
            position: relative;
            width: 33.3333333333%;
            display: flex;
            flex-direction: column;

            .news-card {
                display: flex;
                flex-direction: column;
            }

            &:hover {
                .news-content-overlay {
                    display: block;
                    transition: .5s all ease;
                    animation: slide-in-down .5s ease-out 0s 1
                }
            }

            img {
                border: 5px solid #3c726f;
                display: block;
                margin: auto;
                object-fit: cover;
                width: 100%;
            }

            .news-content-overlay {
                background: linear-gradient(50deg, #43b3bf, #3f9296 55%, #3c726f);
                border-radius: 0 0 10px 10px;
                color: #fff;
                display: none;
                width: 100%;
                height: 100%;
                margin: auto;
                padding: 10% 5%;
                position: absolute;
                top: 0;
                left: 0;


                p {
                    font-size: 1.1vw;
                }

                a {
                    background: #fff;
                    border: none;
                    border-radius: 0;
                    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, .2);
                    color: #363738;
                    display: inline-block;
                    font-size: 1.3vw;
                    font-weight: 700;
                    outline: none;
                    overflow: hidden;
                    padding: 2% 0;
                    text-align: center;
                    width: 50%;
                    text-decoration: none;
                }
            }

            .news-content {
                background-color: #fff;
                padding: 5%;

                h3 {
                    font-size: 1.2vw;
                }
            }
        }
    }
}

// ======================================================================
// Contact Us
// ======================================================================

.contact-page {
    padding: 0 5%;

    .contact-headline {
        @include Headline;

        h2 {
            text-transform: none
        }
    }

    .contact-body {
        @include flex;
        justify-content: flex-start;

        .contact-left {
            width: 50%;
            margin: 1%;
            @include flex;
            flex-direction: column;
            padding: 0 3%;

            .contact-left-body {
                @include flex;
                justify-content: flex-start;
                margin: 2% 0;
                border: 1px solid $lightBlue;
                padding: 3%;
                border-radius: 10px;

                a {
                    margin-bottom: 2%;
                    font-size: 1.2vw;
                }



            }

            .mail,
            .phone {

                svg {
                    width: 50px;
                    height: 50px;

                    path {
                        fill: $lightBlue;
                    }
                }
            }

            .location {
                svg {
                    width: 35px;
                    height: 35px;
                    margin-top: 10%;
                    margin-bottom: 10%
                }
            }

            .contact-left-caption {
                @include flex;
                flex-direction: column;
                margin-left: 5%;

            }



        }

        .contact-right {
            width: 50%;
            margin: 1%;
            // border: 1px solid rgba($color: #000000, $alpha: 1.0);
            padding: 0 3%;

            form {
                @include flex;
                flex-direction: column;

                input,
                textarea {
                    padding: 3% 2%;
                    margin: 1% 0;
                    border: none;
                    border-bottom: 3px solid $lightBlue;
                    color: $lightBlue;
                    outline: none;
                    // height: 9vh;
                    font-size: 1.2vw;
                }

                textarea {
                    flex-grow: 1;
                }

                button {
                    border: none;
                    width: 60%;
                    background: $ttr;
                    color: #fff;
                    padding: 1% 2%;
                    width: 50%;
                    font-size: 2vw;
                    margin-top: 5%;
                    border-radius: 5px;
                }
            }
        }
    }

    .contact-map {
        margin: 5% 0;

    }

}


// ======================================================================
// Footer
// ======================================================================
#footer {
    background: url('https://res.cloudinary.com/ddwk3hbiu/image/upload/v1694512533/Think_Tank/Footer/png-01_uzizcs_aekplx.webp');
    background-repeat: no-repeat;
    background-size: cover;
    color: rgba(0, 0, 0, .6);
    margin-top: -30%;

    .footer-bg {
        height: 80vh;
        object-fit: cover;
        visibility: hidden;
        width: 100%;
    }

    footer {
        align-items: stretch;
        display: flex;
        justify-content: center;
        padding: 0 5%;

        .footer-content {
            margin: 2%;
            width: 33.3333333333%;
        }

        .left {
            img {
                margin-bottom: 5%;
                width: 100%;
            }

            h6 {
                margin: 5% 0;
            }

            .footer-left-body {
                h4 {
                    align-items: center;
                    display: flex;
                    font-size: 1.3vw;

                    img {
                        margin-bottom: 0;
                        width: 6%;
                    }
                }

                p {
                    font-size: 1.1vw;
                }
            }
        }

        .middle {
            form {
                align-items: stretch;
                display: flex;
                flex-direction: column;
                justify-content: center;

                input,
                textarea {
                    background: transparent;
                    border: 1px solid #3c726f;
                    border-radius: 10px;
                    color: #000;
                    margin: 2%;
                    padding: 3% 5%;
                }

                button {
                    background: $mainGradient;
                    border: none;
                    border-radius: 40px;
                    color: #fff;
                    margin: 5% auto;
                    padding: 4% 2%;
                    width: 60%;
                }
            }
        }

        .right {
            h3 {
                font-size: 2vw;
                margin-bottom: 5%;
            }

            p {
                &:nth-child(2n) {
                    margin: 0;
                }

                a {
                    color: rgba(0, 0, 0, .6);
                    font-weight: 400;
                }
            }

            .footer-follow {

                &:last-child {
                    margin: 5% 0;

                    img {
                        width: 60px;
                        margin: 2% 1%;
                        background: #fff;
                        border-radius: 50%;
                    }
                }


                a {
                    svg {
                        background: rgba(0, 0, 0, .1);
                        border-radius: 50%;
                        height: 35px;
                        margin: 0 2%;
                        padding: 2%;
                        width: 35px;

                        path {
                            fill: rgba($color: #000, $alpha: 0.7);
                        }
                    }

                    &:hover {
                        svg {
                            transform: scale(1.2);
                            transition: .5s all ease;
                        }

                        &:nth-child(1) {
                            svg {
                                path {
                                    fill: #db3236;
                                }
                            }
                        }

                        &:nth-child(2) {
                            svg {
                                path {
                                    fill: #0072b1;
                                }
                            }
                        }

                        &:nth-child(3) {
                            svg {
                                path {
                                    fill: #00acee;
                                }
                            }
                        }

                        &:nth-child(4) {
                            svg {
                                path {
                                    fill: #d62976;
                                }
                            }
                        }

                    }
                }


            }
        }
    }

    .copyright {
        border-top: 1px solid rgba(0, 0, 0, .1);
        font-size: 1.3vw;
        margin: 0 5%;
        padding: 1%;
        text-align: center;
        width: 90%;

        p {
            margin: 0;

            span {
                color: #3c726f;
                text-decoration: none;
                font-weight: 500;
            }

            a {
                float: right;
                font-size: 1vw;
                margin-top: 1%;
                text-decoration: none;

                &:hover {
                    color: #000;
                }
            }
        }

        #google_translate_element {
            margin: 1% 0;
        }


    }



}


// ======================================================================
// Privacy Policy
// ======================================================================
.privacy {
    width: 90%;
    margin: 10% auto;

    div {
        margin: 1% 0;
        padding-bottom: 1%;
    }

    h1 {
        font-size: 3vw;
        text-align: center;
        text-transform: capitalize;
        font-weight: bold;
        color: $darkBlue;
        margin: 5% 0;
    }

    h3 {
        font-size: 1.5vw;
        margin: 1% 0;
        font-weight: 600;
        text-transform: capitalize;
    }

    h4 {
        font-size: 1.5vw;
        margin: 1% 0;
        font-weight: 400;
        text-transform: capitalize;
    }

    p {
        font-size: 1.2vw;
        color: rgba($color: #000000, $alpha: 0.6);
    }

    ul {
        padding-left: 5%;
    }

    li {
        font-size: 1.2vw;
        color: rgba($color: #000000, $alpha: 0.6);
        list-style-type: disc;
    }

}