@import 'variables.scss';

$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@mixin headline {
    color: $darkBlue;
    font-size: 25px;
    font-weight: bold;
}

@mixin flex {
    display: flex;
    justify-content: center;
    align-items: stretch;
}

@mixin WhyHover {
    background: $secondaryGradient;
    transition: 500ms all ease;
    color: #fff;
    border: 1px solid #fff;
}

@mixin WhyCardBefore {
    content: "";
    position: absolute;
    top: -8px;
    left: -1px;
    width: calc(100% + 2px);
    height: 8px;
    border-radius: 6px 6px 0 0;
}

@mixin Headline {
    text-align: center;
    text-transform: capitalize;

    span {
        color: #00aeef;
        display: block;
        font-size: 1.3vw;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 15px;
    }

    h2,
    h3 {
        color: #222;
        display: block;
        font-size: 2vw;
        font-weight: 600;
        line-height: 1.28em;
        padding-bottom: 18px;
        position: relative;

        &:before {
            background-color: #d1d2d6;
            bottom: 0;
            content: "";
            height: 3px;
            left: 50%;
            position: absolute;
            -webkit-transform: translate(-50%, 50%);
            transform: translate(-50%, 50%);
            width: 100px;
        }
    }

    @media (min-width: $xs) and (max-width: $sm) and (orientation: portrait) {
        span {
            font-size: 3vw;
            margin-bottom: 0;
        }

        h2 {
            font-size: 2.5vw;
        }
    }

    @media (min-width: 481px) and (max-width: $md) and (orientation: landscape) {
        span {
            font-size: 3vw;
            margin-bottom: 0;
        }

        h2 {
            font-size: 2.5vw;
        }

    }

    @media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {
        span {
            font-size: 3vw;
            margin-bottom: 0;
        }

        h2 {
            font-size: 2.5vw;
        }

    }

    @media (min-width: 768px) and (max-width: $lg) and (orientation: landscape) {
        span {
            font-size: 2vw;
        }

        h2 {
            font-size: 1.5vw;
        }

    }

    @media (min-width: 768px) and (max-width: $lg) and (orientation: portrait) {
        span {
            font-size: 2vw;
        }

        h2 {
            font-size: 1.5vw;
        }
    }

    @media (min-width: 1025px) and (max-width: $xl) {
        span {
            font-size: 2.5vw;
        }

        h2 {
            font-size: 1.5vw;
        }
    }
}

// @mixin xsHeadline {
//     @media (min-width: $xs) and (max-width: $sm) and (orientation: portrait){
//         span {
//             font-size: 3vw;
//             margin-bottom: 0;
//         }

//         h2 {
//             font-size: 2.5vw;
//         }
//     }

//     @media (min-width: 481px) and (max-width: $md) and (orientation: landscape) {
//         span {
//             font-size: 3vw;
//             margin-bottom: 0;
//         }

//         h2 {
//             font-size: 2.5vw;
//         }

//     }

//     @media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {
//         span {
//             font-size: 3vw;
//             margin-bottom: 0;
//         }

//         h2 {
//             font-size: 2.5vw;
//         }

//     }
// }