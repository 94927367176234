$black: #040404;
$lightBlue: #43b3bf;
$darkBlue: #3c726f;
$grey: #445454;
$titleFont: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
$textFont: Georgia, 'Times New Roman', Times, serif;
$styleFont: Tilt Prism, cursive;
$styleFont2: 'Bebas Neue', sans-serif;
$primaryGradient: linear-gradient(50deg, rgba(67, 179, 191, 1) 0%, rgba(63, 146, 150, 1) 0%, rgba(60, 114, 111, 1) 36%);
$secondaryGradient: linear-gradient(50deg, rgba(67, 179, 191, 1) 0%, rgba(63, 146, 150, 1) 55%, rgba(60, 114, 111, 1) 100%);
$mainGradient: linear-gradient(90deg, #43bf94, #43b3bf 35%, #43bf94);
$ttr: linear-gradient(90deg, #57bdaf, #2fafd8 50%, #57bdaf);
$ttrtransparent: linear-gradient(90deg, rgba(87, 189, 175, .5), rgba(47, 175, 216, .5) 50%, rgba(87, 189, 175, .5));
$mainTransparentGradient: linear-gradient(90deg, rgba(87, 189, 175, .8), rgba(47, 175, 216, .9) 50%, rgba(87, 189, 175, .8));
$padding: 2% 5% 0 5%;